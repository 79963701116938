import React from 'react';
import { FaMicrophone } from 'react-icons/fa';

function RecordingModal({ isOpen, onClose, recordingTime, formatTime }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="w-[250px] h-[300px] bg-white rounded-lg shadow-2xl overflow-hidden">
        <div className="flex flex-col items-center justify-center space-y-8 h-full bg-gradient-to-b from-blue-50 to-white">
          <div className="relative">
            <div className="absolute inset-0 bg-red-100 rounded-full animate-ping opacity-75"></div>
            <button
              className="relative w-20 h-20 bg-red-500 text-white rounded-full flex items-center justify-center hover:bg-red-600 transition-colors duration-300 shadow-lg"
              onClick={onClose}
            >
              <FaMicrophone size={30} />
            </button>
          </div>
          <div className="text-xl font-bold text-gray-700">
            {formatTime(recordingTime)}
          </div>
          <div className="flex flex-col items-center space-y-2">
            <div className="text-base font-semibold text-gray-600">
              상담 카드 생성 중
            </div>
            <div className="flex space-x-2">
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecordingModal;