import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, updateDoc, deleteDoc, addDoc, collection } from 'firebase/firestore';
import Modal from './Modal';

function CategoryModal({ isOpen, onClose, category, onSave, user }) {
  const [categoryName, setCategoryName] = useState('');

  useEffect(() => {
    if (category) {
      setCategoryName(category.name);
    } else {
      setCategoryName('');
    }
  }, [category]);

  const handleSave = async () => {
    if (!user) {
      console.error('User is not authenticated.');
      alert('로그인이 필요합니다.');
      return;
    }

    try {
      if (categoryName.trim()) {
        if (category) {
          // 기존 카테고리 수정
          await updateDoc(doc(db, 'categories', category.id), {
            name: categoryName,
            userId: user.uid // 필드명 통일
          });
        } else {
          // 새 카테고리 추가
          await addDoc(collection(db, 'categories'), {
            name: categoryName,
            userId: user.uid // 필드명 통일
          });
        }
        onSave();
        onClose();
      }
    } catch (error) {
      console.error('Error saving category: ', error);
      alert('카테고리 저장 중 오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  const handleDelete = async () => {
    if (!user) {
      console.error('User is not authenticated.');
      alert('로그인이 필요합니다.'); // 사용자에게 경고 메시지 표시
      return;
    }

    if (category && window.confirm('이 카테고리를 삭제하시겠습니까?')) {
      try {
        await deleteDoc(doc(db, 'categories', category.id));
        onSave();
        onClose();
      } catch (error) {
        console.error('Error deleting category: ', error);
        alert('카테고리 삭제 중 오류가 발생했습니다. 다시 시도해 주세요.');
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={category ? "카테고리 관리" : "새 카테고리 추가"}>
      <div className="p-4">
        <input
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          className="w-full p-2 border rounded mb-4"
          placeholder="카테고리 이름"
        />
        <div className="flex justify-end space-x-2">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {category ? '수정' : '추가'}
          </button>
          {category && (
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              삭제
            </button>
          )}
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            취소
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CategoryModal;
