import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import CounselingNotes from './components/CounselingNotes';
import StudentManagement from './components/StudentManagement';
import NewNoteModal from './components/NewNoteModal';
import AuthModal from './components/AuthModal';
import CategoryModal from './components/CategoryModal';
import EditNoteModal from './components/EditNoteModal';
import LandingPage from './components/LandingPage';

function App() {
  const [isNewNoteModalOpen, setIsNewNoteModalOpen] = useState(false);
  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('전체');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isAuthReady, setIsAuthReady] = useState(false);

  // 녹음 관련 상태
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setIsAuthReady(true);
    });

    return () => unsubscribe();
  }, []);

  const openNewNoteModal = () => setIsNewNoteModalOpen(true);
  const closeNewNoteModal = () => {
    setIsNewNoteModalOpen(false);
    setAudioBlob(null);  
  };

  const openEditNoteModal = (note) => {
    setSelectedNote(note);
    setIsEditNoteModalOpen(true);
  };
  const closeEditNoteModal = () => {
    setSelectedNote(null);
    setIsEditNoteModalOpen(false);
  };

  const openCategoryModal = () => setIsCategoryModalOpen(true);
  const closeCategoryModal = () => setIsCategoryModalOpen(false);

  const handleTagClick = (tag) => setSelectedTag(tag);
  const handleCategoryClick = (category) => setSelectedCategory(category);
  const handleStudentClick = (studentName) => setSelectedStudent(studentName);

  // 새로운 onResetTag 함수 추가
  const onResetTag = () => {
    setSelectedTag(null);
  };

  const handleEditNote = (editedNote) => {
    console.log('Edited note:', editedNote);
    setIsEditNoteModalOpen(false);
  };

  const openAuthModal = () => setIsAuthModalOpen(true);
  const closeAuthModal = () => setIsAuthModalOpen(false);

  // 녹음 관련 함수
  const toggleRecording = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      await startRecording();
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
        setAudioBlob(blob);
        chunksRef.current = [];
        openNewNoteModal();
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  if (!isAuthReady) {
    return <div className="flex w-full justify-center items-center h-screen">정보를 불러오는 중입니다...</div>;
  }

  return (
    <Router>
      <div className="flex h-screen bg-white text-gray-800 font-sans">
        {user ? (
          <>
            <Sidebar
              onTagClick={handleTagClick}
              onCategoryClick={handleCategoryClick}
              onStudentClick={handleStudentClick}
              user={user}
              openCategoryModal={openCategoryModal}
            />
            <div className="flex-1 flex flex-col overflow-hidden">
              <Header 
                openNewNoteModal={openNewNoteModal} 
                user={user} 
                openAuthModal={openAuthModal}
                toggleRecording={toggleRecording}
                isRecording={isRecording}
              />
              <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white p-6">
                <Routes>
                  <Route path="/" element={
                    <CounselingNotes 
                      selectedTag={selectedTag} 
                      selectedCategory={selectedCategory} 
                      selectedStudent={selectedStudent} 
                      user={user} 
                      openEditNoteModal={openEditNoteModal}
                      onResetTag={onResetTag} // 새로운 prop 추가
                    />
                  } />
                  <Route path="/students" element={<StudentManagement user={user} />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </main>
            </div>
          </>
        ) : (
          <div className="w-full">
            <Routes>
              <Route path="/" element={<LandingPage openAuthModal={openAuthModal} />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        )}
        {isNewNoteModalOpen && (
          <NewNoteModal 
            isOpen={isNewNoteModalOpen} 
            onClose={closeNewNoteModal} 
            user={user}
            initialAudioBlob={audioBlob}
          />
        )}
        {isEditNoteModalOpen && (
          <EditNoteModal
            isOpen={isEditNoteModalOpen}
            onClose={closeEditNoteModal}
            note={selectedNote}
            onEdit={handleEditNote}
            user={user}
          />
        )}
        {isAuthModalOpen && <AuthModal isOpen={isAuthModalOpen} onClose={closeAuthModal} />}
        {isCategoryModalOpen && <CategoryModal isOpen={isCategoryModalOpen} onClose={closeCategoryModal} user={user} />}
      </div>
    </Router>
  );
}

export default App;