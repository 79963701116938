import React, { useState, useEffect } from 'react';
import { FaPencilAlt, FaSearch, FaChartBar } from 'react-icons/fa';
import Modal from './Modal'; // 기존의 Modal 컴포넌트를 import합니다.

const LandingPage = ({ openAuthModal }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [showPCRecommendationModal, setShowPCRecommendationModal] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // 768px 이하를 모바일로 간주
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleStartClick = () => {
    if (isMobile) {
      setShowPCRecommendationModal(true);
    } else {
      openAuthModal();
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100">
      <header className="bg-white bg-opacity-80 shadow-sm">
        <div className="max-w-6xl mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800">🧃 토트노트</h1>
          <button
            onClick={handleStartClick}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full transition duration-300"
          >
            시작하기
          </button>
        </div>
      </header>
      
      <main className="flex-grow flex justify-center items-center py-12">
        <div className="max-w-5xl w-full px-4">
          <div className="text-center mb-12">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">✨ 교사의 업무 경감을 위한 새로운 상담기록의 경험</h2>
            <p className="text-base text-gray-500">효율적인 상담 기록과 관리를 위한 디지털 상담록</p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <div className="bg-blue-50 p-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg hover:bg-blue-100">
              <FaPencilAlt className="text-2xl text-blue-500 mb-4 transition duration-300 ease-in-out group-hover:scale-110" />
              <h3 className="text-xl font-semibold mb-2">AI 기반 상담기록</h3>
              <p className="text-gray-700">
                AI STT 기능으로 상담기록을 효과적으로 
              </p>
              <p className="text-sm text-gray-500 pt-2">
                버튼 클릭 한번으로 상담 카드 작성과 증빙자료를 한번에 완성할 수 있습니다.
              </p>
            </div>
            <div className="bg-green-50 p-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg hover:bg-green-100">
              <FaSearch className="text-2xl text-green-500 mb-4 transition duration-300 ease-in-out group-hover:scale-110" />
              <h3 className="text-xl font-semibold mb-2">스마트한 검색</h3>
              <p className="text-gray-700">
                태그, 카테고리 기반 강력한 검색 기능 
              </p>
              <p className="text-sm text-gray-500 pt-2">
                쉽고 빠른 색인으로 상담 기록을 효과적으로 조회하며, 학생의 상담 기록을 살펴볼 수 있습니다.
              </p>
            </div>
            <div className="bg-yellow-50 p-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg hover:bg-yellow-100">
              <FaChartBar className="text-2xl text-purple-500 mb-4 transition duration-300 ease-in-out group-hover:scale-110" />
              <h3 className="text-xl font-semibold mb-2">체계적인 관리</h3>
              <p className="text-gray-700">
                학생별, 사안별 상담 기록의 레포팅 기능 
              </p>
              <p className="text-sm text-gray-500 pt-2">
                데이터 기반의 인사이트를 통해 학생들의 생활을 체계적으로 관리하고 분석할 수 있습니다. 
              </p>
            </div>
          </div>

          <div className="bg-white bg-opacity-40 p-8 rounded-lg text-center transition duration-300 ease-in-out hover:bg-opacity-60 hover:shadow-md">
            <h3 className="text-xl font-bold mb-4">About #토트노트 #토노</h3>
            <p className="text-sm text-gray-400">
              토트노트(toTe Note)는 상담의 전 과정을 디지털화하여 선생님의 업무를 경감시키고, 
              더 나은 상담 데이터를 체계적으로 관리할 수 있도록 도와드립니다.<br />
              상담 기록과 부담을 줄이고, 학생들의 성장을 더욱 효과적으로 지원하세요.<br /><br />
              토노는 상담이 또 하나의 업무가 되지 않기를 바라는 교사 1인이 만든 1인 기획/개발 서비스입니다.
            </p>
          </div>
        </div>
      </main>

      <footer className="py-4 mt-auto">
        <div className="max-w-5xl mx-auto px-4 text-center text-gray-600">
          <p className="text-sm">&copy; 2024 토트노트(toTe Note). All rights reserved.</p>
        </div>
      </footer>

      {showPCRecommendationModal && (
        <Modal
          isOpen={showPCRecommendationModal}
          onClose={() => setShowPCRecommendationModal(false)}
          title="최소 해상도 안내" 
          width="w-[300px]"
        >
          <div className="text-base text-center">
            <p className="text-3xl mt-2">🍭</p>
          </div>
          <div className="p-4 text-base text-center rounded-xl">
            원활한 토트노트 사용을 위해<br></br>가로해상도 1,000px 이상의<br></br>PC 브라우저 환경이 요구됩니다!<br></br> 
          </div>
          <div className="mt-5 flex justify-end">
              <button
                onClick={() => setShowPCRecommendationModal(false)}
                className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                확인
              </button>
            </div>
        </Modal>
      )}
    </div>
  );
};

export default LandingPage;