import React, { useState } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Modal from './Modal';

const termsAndConditions = `
# toTe Note 회원가입 약관

## 1. 이용약관 동의 (필수)

1.1 서비스 이용 동의
- 회원은 본 약관을 읽고 동의한 것으로 간주됩니다.
- toTe Note의 모든 서비스는 본 약관에 따라 제공됩니다.

1.2 서비스 변경 및 중단
- 회사는 서비스의 내용을 수정하거나 중단할 수 있는 권리를 보유합니다.

1.3 회원의 의무
- 회원은 타인의 권리를 침해하거나 위법한 행위를 하지 않아야 합니다.
- 자신의 계정 정보를 안전하게 관리해야 합니다.

1.4 책임 제한
- 서비스는 회원 간 발생한 분쟁에 대해 책임지지 않습니다.
- 천재지변, 기술적 결함 등으로 인한 서비스 중단에 대해 책임지지 않습니다.

## 2. 개인정보 수집 및 이용 동의 (필수)

2.1 수집하는 회원 개인정보 항목
- 필수: 이메일 주소, 비밀번호(암호화 저장)

2.2 개인정보의 수집 및 이용목적
- 서비스 제공 및 관리

2.3 개인정보의 보유 및 이용 기간
- 회원 탈퇴 시 즉시 파기

2.4 동의 거부 권리 및 거부 시 불이익
- 본 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다.
- 다만, 동의 거부 시 toTe Note 서비스 이용이 제한될 수 있습니다.
`;

function AuthModal({ isOpen, onClose }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const toggleMode = () => {
    setIsSignUp(!isSignUp);
    setAgreeToTerms(false);
  };

  const handleAuth = async () => {
    try {
      setError(null);
      if (isSignUp) {
        if (!agreeToTerms) {
          setError("약관에 동의해야 합니다.");
          return;
        }
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      onClose();
    } catch (err) {
      // Map Firebase errors to custom messages
      switch (err.code) {
        case 'auth/invalid-credential':
          setError("존재하지 않는 회원입니다.");
          break;
        case 'auth/email-already-in-use':
          setError("이미 가입된 회원입니다.");
          break;
        default:
          setError(err.message);
          break;
      }
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={isSignUp ? "회원가입" : "로그인"} width="w-[400px]">
      <div className="p-4">
        <div className="mb-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="이메일 주소"
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호"
            className="w-full p-2 border rounded"
          />
        </div>
        {isSignUp && (
          <div className="mb-4">
            <div className="h-40 overflow-y-scroll border p-2 mb-2 text-sm">
              <pre className="font-sans">{termsAndConditions}</pre>
            </div>
            <label className="flex items-center text-sm">
              <input
                type="checkbox"
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
                className="mr-1"
              />
              약관에 동의합니다
            </label>
          </div>
        )}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="flex flex-col space-y-4 mb-4">
          <button 
            onClick={handleAuth} 
            className="bg-blue-500 text-white px-4 py-2 rounded w-full"
            disabled={isSignUp && !agreeToTerms}
          >
            {isSignUp ? "회원가입 하기" : "로그인"}
          </button>
          <button onClick={toggleMode} className="text-blue-500 text-sm w-full">
            {isSignUp ? "이미 계정이 있으신가요? 로그인" : "아직 계정이 없으신가요? 회원가입 하기"}
          </button>
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleGoogleSignIn}
            className="bg-pink-500 text-white px-4 py-2 rounded w-full"
          >
            구글 계정으로 {isSignUp ? "회원가입" : "로그인"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AuthModal;
