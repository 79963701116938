import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { db, storage } from '../firebase';
import { collection, addDoc, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import Modal from './Modal';
import { useDropzone } from 'react-dropzone';
import { FaTrash, FaSpinner, FaMicrophone, FaUserPlus, FaTimes } from 'react-icons/fa';
import RecordingModal from './RecordingModal';

const API_URL = process.env.REACT_APP_API_URL;
const colors = [
  { name: 'Pastel Red', value: 'bg-red-200' },
  { name: 'Pastel Green', value: 'bg-green-200' },
  { name: 'Pastel Blue', value: 'bg-blue-200' },
  { name: 'Pastel Yellow', value: 'bg-yellow-200' },
  { name: 'Pastel Purple', value: 'bg-purple-200' }
];

function NewNoteModal({ isOpen, onClose, user, initialAudioBlob }) {

  const [note, setNote] = useState({
    title: '',
    content: '',
    category: '',
    date: new Date(new Date().getTime() + 9 * 60 * 60 * 1000).toISOString().slice(0, 16),
    color: colors[0].value,
    tags: [],
    files: []
  });
  const [categories, setCategories] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [matchedStudents, setMatchedStudents] = useState([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [isRecordingModalOpen, setIsRecordingModalOpen] = useState(false);

  const processedAudioBlobRef = useRef(null); // Ref to track processed Blob
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const timerRef = useRef(null);
  const quillRef = useRef(null);
  const [isStudentListLoaded, setIsStudentListLoaded] = useState(false); // 학생 목록이 로드되었는지 여부를 추적

  const handleStudentSelect = (student) => {
    if (selectedStudents.includes(student)) {
      setSelectedStudents(selectedStudents.filter(s => s !== student));
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  };

  const handleRemoveStudent = (student) => {
    setSelectedStudents(selectedStudents.filter((s) => s !== student));
  };

  const extractHashtags = useCallback((content) => {
    const hashtags = content.match(/#[a-zA-Z0-9_가-힣]+/g) || [];
    const uniqueTags = [...new Set(hashtags.map(tag => tag.slice(1)))];
    setNote(prevNote => ({ ...prevNote, tags: uniqueTags }));
  }, []);

  const handleContentChange = useCallback((content) => {
    setNote(prevNote => ({ ...prevNote, content }));
    extractHashtags(content);
  }, [extractHashtags]);

  const appendToContent = useCallback((newContent) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const length = quill.getLength();
      const now = new Date();
      const formattedDate = now.toLocaleDateString('ko-KR', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      });
      const formattedTime = now.toLocaleTimeString('ko-KR', { 
        hour: '2-digit', 
        minute: '2-digit' 
      });
      const prefix = `[${formattedDate} ${formattedTime}] `;
      quill.insertText(length, prefix + newContent + '\n');
    }
  }, []);

  const matchStudents = useCallback((text) => {
    const matched = students.filter(student => 
      text.toLowerCase().includes(student.toLowerCase())
    );
    setMatchedStudents(matched);
  }, [students]);

  const transcribeAudio = useCallback(async (blob) => {
    if (!blob) return;
  
    let filename = blob.name || 'audio.wav';
    const fileExtension = filename.split('.').pop().toLowerCase();
    const supportedFormats = ['flac', 'm4a', 'mp3', 'mp4', 'mpeg', 'mpga', 'oga', 'ogg', 'wav', 'webm'];
  
    if (!supportedFormats.includes(fileExtension)) {
      console.error('지원되지 않는 파일 형식:', fileExtension);
      setErrorMessage(`지원되지 않는 파일 형식입니다: ${fileExtension}`);
      return;
    }
  
    const formData = new FormData();
    formData.append('file', blob, filename);
  
    try {
      const response = await fetch(`${API_URL}/transcribe`, {
        method: 'POST',
        body: formData
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      appendToContent(data.text);
      matchStudents(data.text); // 전사된 텍스트로 학생 매칭
    } catch (error) {
      console.error('Error transcribing audio:', error);
      setErrorMessage('음성 변환 중 오류가 발생했습니다.');
    }
  }, [appendToContent, matchStudents]);

  useEffect(() => {
    if (isRecording) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isRecording]);

  // initialAudioBlob 처리 로직을 하나의 useEffect로 통합
  useEffect(() => {
    if (isStudentListLoaded && initialAudioBlob && initialAudioBlob !== processedAudioBlobRef.current) {
      
      const newFile = {
        name: `녹음파일_${Date.now()}.wav`,
        file: initialAudioBlob
      };
      
      setNote(prevNote => ({
        ...prevNote,
        files: [...prevNote.files, newFile]
      }));

      transcribeAudio(initialAudioBlob); // 오디오 파일을 전사
      processedAudioBlobRef.current = initialAudioBlob; // Mark this blob as processed
    }
  }, [initialAudioBlob, transcribeAudio, isStudentListLoaded]);

  useEffect(() => {
    if (matchedStudents.length > 0) {
      setSelectedStudents((prev) => [
        ...new Set([...prev, ...matchedStudents])
      ]);
    }
  }, [matchedStudents]);

  const fetchCategories = useCallback(async () => {
    try {
      const categoryQuery = query(
        collection(db, 'categories'),
        where('userId', '==', user.uid)
      );
      const categorySnapshot = await getDocs(categoryQuery);
      const categoryList = categorySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name
      }));
      setCategories(categoryList);
    } catch (error) {
      console.error('카테고리 목록 가져오기 실패:', error);
      setErrorMessage('카테고리 목록을 불러오는 데 실패했습니다.');
    }
  }, [user]);

  const fetchStudents = useCallback(async () => {
    try {
      const classQuery = query(
        collection(db, 'classes'),
        where('userId', '==', user.uid)
      );
      const classSnapshot = await getDocs(classQuery);
      const studentList = [];
      classSnapshot.forEach(doc => {
        const classData = doc.data();
        classData.students.forEach(student => studentList.push(student.name));
      });
      setStudents(studentList);
      setIsStudentListLoaded(true); // 학생 목록이 로드되었음을 표시
    } catch (error) {
      console.error('학생 목록 가져오기 실패:', error);
      setErrorMessage('학생 목록을 불러오는 데 실패했습니다.');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchCategories();
      fetchStudents();
    }
  }, [user, fetchCategories, fetchStudents]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNote(prevNote => ({ ...prevNote, [name]: value }));
  };

  const handleColorChange = (colorValue) => {
    setNote(prevNote => ({ ...prevNote, color: colorValue }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setNote(prevNote => ({
        ...prevNote,
        files: [...prevNote.files, ...acceptedFiles.map(file => ({ name: file.name, file }))]
      }));
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleFileDelete = async (fileToDelete) => {
    try {
      if (fileToDelete.url) {
        const fileRef = ref(storage, fileToDelete.url);
        await deleteObject(fileRef);
      }
      setNote(prevNote => ({
        ...prevNote,
        files: prevNote.files.filter(file => file !== fileToDelete)
      }));
    } catch (error) {
      console.error('파일 삭제 중 오류 발생: ', error);
      setErrorMessage('파일 삭제 중 오류가 발생했습니다.');
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      chunksRef.current = [];
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };
      
      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
        chunksRef.current = [];
        
        const audioRef = ref(storage, `counseling_audio/${Date.now()}.wav`);
        await uploadBytes(audioRef, blob);
        const audioUrl = await getDownloadURL(audioRef);

        setNote(prevNote => ({
          ...prevNote,
          files: [...prevNote.files, { name: `녹음파일_${Date.now()}.wav`, url: audioUrl }]
        }));

        transcribeAudio(blob);
        
        setSelectedStudents((prev) => [...new Set([...prev, ...matchedStudents])]);

        setIsRecordingModalOpen(false);
        setIsRecording(false);
      };
      
      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingTime(0);
      setIsRecordingModalOpen(true);
      setErrorMessage('');
    } catch (error) {
      console.error('마이크 접근 오류:', error);
      setErrorMessage('마이크 접근에 실패했습니다. 권한을 확인해주세요.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(timerRef.current);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
        // 제목이 비어 있으면 "제목 없음"으로 설정
        const titleToSave = note.title.trim() === '' ? '제목 없음' : note.title;

        const fileUploads = await Promise.all(
            note.files.map(async fileObj => {
                if (fileObj.file) {
                    const fileRef = ref(storage, `counseling_files/${fileObj.name}`);
                    await uploadBytes(fileRef, fileObj.file);
                    const url = await getDownloadURL(fileRef);
                    return { name: fileObj.name, url };
                }
                return fileObj;
            })
        );

        const kstDate = new Date(note.date);
        const utcDate = new Date(kstDate.getTime() - 9 * 60 * 60 * 1000);

        const categoryToSave = note.category || "없음";

        const studentsToSave = selectedStudents.length > 0 ? selectedStudents : ["없음"];


        await Promise.all(studentsToSave.map(async (student) => {
            await addDoc(collection(db, 'counselingNotes'), {
                ...note,
                title: titleToSave, // 저장할 때 수정된 제목 사용
                category: categoryToSave,
                student: student,
                files: fileUploads,
                userId: user.uid,
                createdAt: Timestamp.fromDate(utcDate)
            });
        }));

        onClose();
    } catch (error) {
        console.error('상담 기록 저장 중 오류 발생: ', error);
        setErrorMessage('상담 기록 저장 중 오류가 발생했습니다.');
    } finally {
        setIsSubmitting(false);
    }
};


  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        title="새 상담 기록"
        width="w-[60vw]"
        height="h-[90vh]"
      >
        <form onSubmit={handleSubmit} className="space-y-4 h-full flex flex-col">
          <div className="flex justify-between items-center">
            <input
              type="text"
              name="title"
              value={note.title}
              onChange={handleChange}
              placeholder="상담 제목"
              className="text-xl font-bold text-gray-800 w-1/3 p-2 border-b border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <div className="flex items-center">
              <select
                value={note.category}
                onChange={handleChange}
                name="category"
                className="mr-2 p-2 border rounded"
              >
                <option value="">카테고리 선택</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
              <input
                type="datetime-local"
                name="date"
                value={note.date}
                onChange={handleChange}
                className="ml-2 p-2 border rounded"
                required
              />
            </div>
          </div>
          
          <div className="flex items-center">
            <FaUserPlus
              className="cursor-pointer text-blue-500"
              onClick={() => {
                setIsStudentModalOpen(true);
              }}
              size={24}
            />
            <div className="flex flex-wrap gap-2 ml-2">
              {selectedStudents.map((student, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-base font-medium px-2.5 py-0.5 rounded inline-flex items-center">
                  {student}
                  <FaTimes className="ml-2 cursor-pointer" onClick={() => handleRemoveStudent(student)} />
                </span>
              ))}
            </div>
          </div>
          
          <div className="flex-grow relative">
            <ReactQuill
              ref={quillRef}
              value={note.content}
              onChange={handleContentChange}
              className="h-calc-100-minus-40"
              placeholder="상담 내용을 입력하세요. 해시태그를 사용하려면 #을 입력하세요. (예: #진로상담)"
            />
            <button
              type="button"
              onClick={startRecording}
              className="absolute bottom-4 right-4 w-10 h-10 bg-red-500 text-white rounded-full flex items-center justify-center hover:bg-red-600"
            >
              <FaMicrophone />
            </button>
          </div>
          
          <div className="flex flex-wrap mt-2">
            {note.tags.map((tag, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                #{tag}
              </span>
            ))}
          </div>

          <div {...getRootProps({ className: 'dropzone' })} className="p-4 border-2 border-dashed rounded-lg cursor-pointer">
            <input {...getInputProps()} />
            <p className="text-center text-gray-500">여기에 파일을 드래그하거나 클릭하여 업로드하세요</p>
          </div>

          {note.files.length > 0 && (
            <div className="mt-4">
              <h4 className="font-semibold mb-2">업로드된 파일:</h4>
              <div className="flex flex-wrap">
                {note.files.map((file, index) => (
                  <div 
                    key={index} 
                    className="flex items-center bg-gray-100 p-2 rounded-lg mr-2 mb-2"
                    style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    <span className="text-gray-800 truncate">
                      {file.name}
                    </span>
                    <FaTrash 
                      className="ml-2 text-red-500 cursor-pointer hover:text-red-700"
                      onClick={() => handleFileDelete(file)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Error:</strong>
              <span className="block sm:inline"> {errorMessage}</span>
            </div>
          )}

          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              {colors.map((color) => (
                <button
                  key={color.value}
                  type="button"
                  onClick={() => handleColorChange(color.value)}
                  className={`w-8 h-8 rounded-full mr-2 ${note.color === color.value ? 'ring-2 ring-offset-2 ring-blue-500' : ''} ${color.value}`}
                />
              ))}
            </div>
            <div className="flex space-x-2 items-center">
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                disabled={isSubmitting || isRecording}
              >
                취소
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center"
                disabled={isSubmitting || isRecording}
              >
                {isSubmitting ? <FaSpinner className="animate-spin mr-2" /> : null}
                저장
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {isStudentModalOpen && (
  <Modal
    isOpen={isStudentModalOpen}
    onClose={() => {
      setIsStudentModalOpen(false);
    }}
    title="학생 선택"
    width="w-[400px]"
  >
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto mb-4" style={{maxHeight: '300px'}}>
        {students.map((student, index) => (
          <div key={index} className="flex items-center mb-2 last:mb-0">
            <input
              type="checkbox"
              id={`student-${index}`}
              className="hidden"
              checked={selectedStudents.includes(student)}
              onChange={() => handleStudentSelect(student)}
            />
            <label
              htmlFor={`student-${index}`}
              className="flex items-center cursor-pointer text-gray-800 hover:bg-gray-100 p-2 rounded-md w-full"
            >
              <span className={`w-5 h-5 inline-block mr-2 rounded-sm border ${selectedStudents.includes(student) ? 'bg-blue-500 border-blue-500' : 'border-gray-400'}`}>
                {selectedStudents.includes(student) && (
                  <svg className="w-5 h-5 text-white fill-current" viewBox="0 0 20 20">
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                )}
              </span>
              {student}
            </label>
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center">
        <span className="text-sm text-gray-600">
          {selectedStudents.length} 명의 학생 선택됨
        </span>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
          onClick={() => {
            setIsStudentModalOpen(false);
          }}
        >
          확인
        </button>
      </div>
    </div>
  </Modal>
)}

      <RecordingModal
        isOpen={isRecordingModalOpen}
        onClose={stopRecording}
        recordingTime={recordingTime}
        formatTime={formatTime}
      />
    </>
  );
}

export default NewNoteModal;
