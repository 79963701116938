import React from 'react';

function StudentManagement() {
  return (
    <div>
      <h2>학생 관리</h2>
      <table>
        <thead>
          <tr>
            <th>번호</th>
            <th>이름</th>
            <th>기본 사항</th>
          </tr>
        </thead>
        <tbody>
          {/* 학생 목록을 여기에 추가 */}
        </tbody>
      </table>
    </div>
  );
}

export default StudentManagement;