import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, where, query, onSnapshot } from 'firebase/firestore';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import ClassManagementModal from './ClassManagementModal';
import CategoryModal from './CategoryModal';
import DevelopHistoryModal from './DevelopHistoryModal';

function Sidebar({ onTagClick, onCategoryClick, onStudentClick = () => {}, user }) {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [classes, setClasses] = useState([]);
  const [isClassManagementOpen, setIsClassManagementOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isDevelopHistoryOpen, setIsDevelopHistoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [foldedClasses, setFoldedClasses] = useState({});

  const fetchCategories = useCallback(async () => {
    if (!user) return;

    try {
      const q = query(
        collection(db, 'categories'),
        where('userId', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);
      const categoryList = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
      setCategories(categoryList);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, [user]);

  const fetchClasses = useCallback(async () => {
    if (!user) return;

    try {
      const q = query(
        collection(db, 'classes'),
        where('userId', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);
      const classList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClasses(classList);

      const initialFoldedState = {};
      classList.forEach((classData) => {
        initialFoldedState[classData.id] = true;
      });
      setFoldedClasses(initialFoldedState);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  }, [user]);

  const subscribeToTags = useCallback(() => {
    if (!user) return;

    const q = query(
      collection(db, 'counselingNotes'),
      where('userId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const allTags = new Set();
      querySnapshot.forEach((doc) => {
        const noteTags = doc.data().tags || [];
        noteTags.forEach(tag => allTags.add(tag));
      });
      setTags(Array.from(allTags));
    });

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    fetchCategories();
    fetchClasses();
    const unsubscribeTags = subscribeToTags();

    return () => {
      if (unsubscribeTags) unsubscribeTags();
    };
  }, [fetchCategories, fetchClasses, subscribeToTags]);

  const handleCategoryClick = (category) => {
    const newCategory = selectedCategory === category ? null : category;
    setSelectedCategory(newCategory);
    onCategoryClick(newCategory);
  };

  const handleTagClick = (tag) => {
    const newTag = selectedTag === tag ? null : tag;
    setSelectedTag(newTag);
    onTagClick(newTag);
  };

  const handleStudentClick = (studentName) => {
    const newStudent = selectedStudent === studentName ? null : studentName;
    setSelectedStudent(newStudent);
    onStudentClick(newStudent);
  };

  const handleClassToggle = (classId) => {
    setFoldedClasses((prevFoldedClasses) => ({
      ...prevFoldedClasses,
      [classId]: !prevFoldedClasses[classId],
    }));
  };

  const openCategoryModal = (category = null) => {
    setSelectedCategory(category);
    setIsCategoryModalOpen(true);
  };

  return (
    <div className="w-64 bg-white border-r border-gray-200 p-4 flex flex-col h-full justify-between">
      <div>
        <h1 className="text-xl font-bold ml-2 mt-2 mb-6 text-gray-800">🧃 토트노트</h1>
        <nav className="mb-4">
          <div
            className={`flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-100 cursor-pointer text-gray-700 ${selectedCategory === '전체' ? 'bg-green-100' : ''}`}
            onClick={() => handleCategoryClick('전체')}
          >
            <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
            <span>전체 상담카테고리</span>
          </div>
          {categories.map((category) => (
            <div
              key={category.id}
              className={`flex items-center justify-between py-2 px-4 rounded hover:bg-gray-100 cursor-pointer text-gray-700 ${selectedCategory === category.name ? 'bg-pink-100' : ''}`}
              onClick={() => handleCategoryClick(category.name)}
            >
              <div className="flex items-center space-x-2 flex-grow">
                <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                <span>{category.name}</span>
              </div>
              <button onClick={(e) => { e.stopPropagation(); openCategoryModal(category); }} className="text-gray-500 hover:text-gray-700">
                🗂️
              </button>
            </div>
          ))}
        </nav>
        <button
          onClick={() => openCategoryModal()}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md mb-6"
        >
          + 카테고리 추가
        </button>
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">태그</h2>
          <div className="flex flex-wrap">
            {tags.length > 0 ? (
              tags.map((tag, index) => (
                <button
                  key={index}
                  onClick={() => handleTagClick(tag)}
                  className={`bg-gray-200 text-gray-700 rounded-full px-4 py-1 text-sm mr-2 mb-2 hover:bg-gray-300 ${selectedTag === tag ? 'bg-green-100' : ''}`}
                >
                  {tag}
                </button>
              ))
            ) : (
              <p className="text-gray-500 text-sm">등록된 태그 없음</p>
            )}
          </div>
        </div>
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-semibold">학급 목록</h2>
            <button
              onClick={() => setIsClassManagementOpen(true)}
              className="text-gray-500 hover:text-gray-700"
            >
              🛠️
            </button>
          </div>
          {classes.length > 0 ? (
            classes.map((classData) => (
              <div key={classData.id} className="mb-4">
                <div
                  className="flex items-center justify-between cursor-pointer bg-gray-200 py-2 px-3 rounded hover:bg-gray-300"
                  onClick={() => handleClassToggle(classData.id)}
                >
                  <h3 className="text-md font-semibold flex-grow">
                    {classData.name}
                  </h3>
                  <span className="text-gray-500">
                    {foldedClasses[classData.id] ? <FaChevronRight /> : <FaChevronDown />}
                  </span>
                </div>
                {!foldedClasses[classData.id] && (
                  <div className="max-h-40 overflow-y-scroll mt-2">
                    <ul>
                      {classData.students.map((student, index) => (
                        <li key={index}
                          onClick={() => handleStudentClick(student.name)}
                          className={`text-base font-lg text-gray-700 py-1 px-2 rounded cursor-pointer hover:bg-blue-100 ${selectedStudent === student.name ? 'bg-blue-50' : ''}`}
                        >
                          🍀 {student.number}번 {student.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))
          ) : (
            <button
              onClick={() => setIsClassManagementOpen(true)}
              className="w-full bg-red-500 hover:bg-red-600 text-white py-2 rounded-md"
            >
              학급 등록 필요
            </button>
          )}
        </div>
      </div>
      <div>
        <div
          className="text-center text-xs font-semibold text-gray-500 mb-1 bg-green-100 rounded inline-block p-1 pl-2 pr-2 cursor-pointer"
          onClick={() => setIsDevelopHistoryOpen(true)} // Open the modal on click
        >
          Beta v0.4.1
        </div>
        <div className="text-left text-xs text-gray-500 mt-1">
          ©2024.toTeNote, All Right Reserved.
        </div>
      </div>
      {isCategoryModalOpen && (
        <CategoryModal
          isOpen={isCategoryModalOpen}
          onClose={() => setIsCategoryModalOpen(false)}
          category={selectedCategory}
          onSave={fetchCategories}
          user={user}
        />
      )}
      {isClassManagementOpen && (
        <ClassManagementModal
          isOpen={isClassManagementOpen}
          onClose={() => setIsClassManagementOpen(false)}
          onSave={fetchClasses}
          user={user}
        />
      )}
      {isDevelopHistoryOpen && ( // Show the development history modal
        <DevelopHistoryModal
          isOpen={isDevelopHistoryOpen}
          onClose={() => setIsDevelopHistoryOpen(false)}
        />
      )}
    </div>
  );
}

export default Sidebar;
