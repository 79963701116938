import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, where, query } from 'firebase/firestore';
import Modal from './Modal';

function ClassManagementModal({ isOpen, onClose, user, onSave }) {
  const [classData, setClassData] = useState(null);
  const [className, setClassName] = useState('');
  const [students, setStudents] = useState([{ number: '', name: '' }]);
  const [isEditing, setIsEditing] = useState(false);

  const fetchClass = useCallback(async () => {
    if (!user) return;

    try {
      const q = query(collection(db, 'classes'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs[0].data();
        setClassData({ id: querySnapshot.docs[0].id, ...data });
        setClassName(data.name);
        setStudents(data.students);
      } else {
        setClassData(null);
        setClassName('');
        setStudents([{ number: '', name: '' }]);
      }
    } catch (error) {
      console.error("Error fetching class:", error);
    }
  }, [user]);

  useEffect(() => {
    fetchClass();
  }, [fetchClass]);

  const handleStudentChange = (index, field, value) => {
    const newStudents = [...students];
    newStudents[index][field] = value;
    setStudents(newStudents);
  };

  const addStudent = () => {
    setStudents([...students, { number: '', name: '' }]);
  };

  const removeStudent = (index) => {
    const newStudents = students.filter((_, i) => i !== index);
    setStudents(newStudents);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (classData) {
        await updateDoc(doc(db, 'classes', classData.id), {
          name: className,
          students: students.filter(student => student.number && student.name),
          userId: user.uid
        });
      } else {
        await addDoc(collection(db, 'classes'), {
          name: className,
          students: students.filter(student => student.number && student.name),
          userId: user.uid
        });
      }
      fetchClass();
      onSave();
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving class: ", error);
    }
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    if (classData) {
      setClassName(classData.name);
      setStudents(classData.students);
    } else {
      setClassName('');
      setStudents([{ number: '', name: '' }]);
    }
  };

  const deleteClass = async () => {
    if (!classData) return;
    try {
      await deleteDoc(doc(db, 'classes', classData.id));
      fetchClass();
      onSave();
    } catch (error) {
      console.error("Error deleting class: ", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="학급 관리">
      <div className="max-h-[70vh] overflow-y-auto px-4">
        {!classData || isEditing ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="className">
                학급 이름
              </label>
              <input
                type="text"
                id="className"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div>
              <h3 className="text-base font-semibold mb-2">학생 목록</h3>
              {students.map((student, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="number"
                    value={student.number}
                    onChange={(e) => handleStudentChange(index, 'number', e.target.value)}
                    placeholder="번호"
                    className="shadow appearance-none border rounded w-1/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                  />
                  <input
                    type="text"
                    value={student.name}
                    onChange={(e) => handleStudentChange(index, 'name', e.target.value)}
                    placeholder="이름"
                    className="shadow appearance-none border rounded w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                  />
                  <button 
                    type="button" 
                    onClick={() => removeStudent(index)}
                    className="text-gray-500 hover:text-red-600 transition-colors duration-300"
                    aria-label="Delete student"
                  >
                    🗑️
                  </button>
                </div>
              ))}
              <button 
                type="button" 
                onClick={addStudent} 
                className="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                + 학생 추가
              </button>
            </div>
            <div className="flex justify-end space-x-2 pt-4">
              <button 
                type="submit" 
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                {classData ? '수정' : '저장'}
              </button>
              {classData && (
                <button 
                  type="button" 
                  onClick={cancelEditing} 
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  취소
                </button>
              )}
            </div>
          </form>
        ) : (
          <div className="bg-white overflow-hidden">
            <div>
              <div className="font-bold text-xl mb-2">{classData.name}</div>
              <p className="text-gray-700 text-base mb-4">
                총 학생 수: {classData.students.length}명
              </p>
              <div className="mb-4">
                <h5 className="font-semibold mb-2">학생 목록:</h5>
                <ul className="divide-y divide-gray-200">
                  {classData.students.map((student, index) => (
                    <li key={index} className="py-2 flex justify-between items-center">
                      <span>{student.number}. {student.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="py-4 flex justify-center space-x-2">
              <button 
                onClick={startEditing} 
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                수정
              </button>
              <button 
                onClick={deleteClass} 
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                삭제
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ClassManagementModal;