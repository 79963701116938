import React from 'react';
import Modal from './Modal';

function DevelopHistoryModal({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="📝 업데이트 History"
      width="w-96"
      height="h-auto"
    >
      <div className="text-sm mb-4 h-48 overflow-y-auto">
        <p className="mb-2">
          <strong>v0.5.0 예정</strong>
        </p>
        <ul className="list-disc pl-5">
          <li>레포트 AI 적용</li>
          <li>NEIS 누가 기록용 레포트 출력</li>
        </ul>
        <p className="mt-4 mb-2">
          <strong>v0.4.1</strong>
        </p>
        <ul className="list-disc pl-5">
          <li>AI STT 적용</li>
          <li>도메인 퍼블리싱</li>
          <li>카드 애니메이션 적용</li>
          <li>태그삭제 연계 to-do 기능 구현</li>
          <li>태그삭제시 빈페이지 초기화 기능 추가</li>

        </ul>
        <p className="mt-4 mb-2">
          <strong>v0.1.0</strong>
        </p>
        <ul className="list-disc pl-5">
          <li>클래스 설정</li>
          <li>사용자 가입 기능 추가</li>
          <li>상담 카드 멀티 대상 설정 추가</li>
          <li>상담 카드 컬러 레이블 설정 기능</li>
          <li>카테고리 / 태그 정렬 기능</li>
          <li>웹 퍼블리시</li>
        </ul>
      </div>

      <div className="text-sm text-gray-600 mb-4">
        <p>😀개발자 : 이상선 (indend007@gmail.com)</p>
        <p>📝Task icons created by Freepik - Flaticon</p>
      </div>
      <button
        onClick={onClose}
        className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md"
      >
        닫기
      </button>
    </Modal>
  );
}

export default DevelopHistoryModal;