import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, orderBy, onSnapshot, where, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import EditNoteModal from './EditNoteModal';
import { FaSortAmountDown, FaSortAmountUp, FaSearch, FaFileAlt, FaDownload, FaTimes } from 'react-icons/fa';
import Modal from './Modal';
import FlipMove from 'react-flip-move';
import * as XLSX from 'xlsx';
import DOMPurify from 'dompurify';
import { getStorage, ref, deleteObject } from 'firebase/storage'; 

import { getApp } from 'firebase/app';
const app = getApp();
const storage = getStorage(app);

function CounselingNotes({ selectedTag, selectedCategory, selectedStudent, user, onResetTag }) {
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [modalSortOrder, setModalSortOrder] = useState('desc');
  const [isTagFilterActive, setIsTagFilterActive] = useState(false);
  
  const convertToDate = (dateField) => {
    if (!dateField) return null;
    if (dateField instanceof Date) return dateField;
    if (typeof dateField.toDate === 'function') return dateField.toDate();
    if (typeof dateField === 'number') return new Date(dateField);
    if (typeof dateField === 'string') return new Date(dateField);
    if (dateField.seconds) return new Date(dateField.seconds * 1000);
    return null;
  };
  
  const formatDate = (dateField) => {
    const date = convertToDate(dateField);
    if (!date || !(date instanceof Date) || isNaN(date)) return 'Invalid Date';
    
    return date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).replace(/\. /g, '.').replace(/\.$/, '');
  };

  useEffect(() => {
    if (user) {
      let q = query(
        collection(db, 'counselingNotes'),
        where('userId', '==', user.uid),
        orderBy('createdAt', sortOrder)
      );

      if (selectedTag) {
        q = query(q, where('tags', 'array-contains', selectedTag));
        setIsTagFilterActive(true);
      } else {
        setIsTagFilterActive(false);
      }

      if (selectedCategory && selectedCategory !== '전체') {
        q = query(q, where('category', '==', selectedCategory));
      }

      if (selectedStudent) {
        q = query(q, where('student', '==', selectedStudent));
      }

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const notesData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: data.date,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt || null,
          };
        });
        setNotes(notesData);
        setFilteredNotes(notesData);
      });

      return () => unsubscribe();
    }
  }, [selectedTag, selectedCategory, selectedStudent, user, sortOrder]);

  useEffect(() => {
    const filtered = notes.filter((note) =>
      note.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      note.student.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      note.content.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setFilteredNotes(filtered);
  }, [searchKeyword, notes]);

  const handleNoteClick = (note) => {
    setSelectedNote(note);
  };

  const closeModal = () => {
    setSelectedNote(null);
  };

  const handleEditNote = (editedNote) => {
    setNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === editedNote.id ? editedNote : note))
    );
    closeModal();
  };

  const getPlainText = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const toggleModalSortOrder = () => {
    setModalSortOrder(modalSortOrder === 'desc' ? 'asc' : 'desc');
  };

  const handleDeleteClick = (note) => {
    setNoteToDelete(note);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (noteToDelete) {
      try {
        const noteRef = doc(db, 'counselingNotes', noteToDelete.id);
        const noteDoc = await getDoc(noteRef);

        if (noteDoc.exists()) {
          const noteData = noteDoc.data();

          if (noteData.files && noteData.files.length > 0) {
            for (let file of noteData.files) {
              if (file.url) {
                try {
                  const fileRef = ref(storage, file.url);
                  await deleteObject(fileRef);
                } catch (error) {
                  if (error.code !== 'storage/object-not-found') {
                    console.error('Error deleting file:', error);
                  }
                }
              }
            }
          }

          await deleteDoc(noteRef);

          setNotes(notes.filter(note => note.id !== noteToDelete.id));
          setFilteredNotes(filteredNotes.filter(note => note.id !== noteToDelete.id));
          setIsDeleteModalOpen(false);
        }
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    }
  };

  const handleReportClick = () => {
    setIsReportModalOpen(true);
  };

  const sortedFilteredNotes = [...filteredNotes].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return modalSortOrder === 'desc' ? dateB - dateA : dateA - dateB;
  });

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedFilteredNotes.map(note => ({
      '학생 이름': note.student,
      '제목': note.title,
      '내용': getPlainText(note.content),
      '날짜': formatDate(note.date),
      '수정 날짜': note.updatedAt ? formatDate(note.updatedAt) : ''
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "상담 기록");
    XLSX.writeFile(workbook, "상담_기록_리포트.xlsx");
  };

  const handleRemoveTag = async (noteId, tagToRemove) => {
    try {
      const noteRef = doc(db, 'counselingNotes', noteId);
      const noteDoc = await getDoc(noteRef);
      
      if (noteDoc.exists()) {
        const noteData = noteDoc.data();
        let updatedContent = noteData.content;
        
        const parser = new DOMParser();
        const doc = parser.parseFromString(updatedContent, 'text/html');
  
        const elements = doc.getElementsByTagName('p');
        for (let element of elements) {
          if (element.textContent.includes(`#${tagToRemove}`)) {
            element.textContent = element.textContent.replace(`#${tagToRemove}`, '').trim();
            if (element.textContent === '') {
              element.remove();
            }
          }
        }
  
        updatedContent = new XMLSerializer().serializeToString(doc.body);
        updatedContent = updatedContent.replace(/^<body>|<\/body>$/g, '');
  
        updatedContent = DOMPurify.sanitize(updatedContent);
        
        const updatedTags = noteData.tags.filter(tag => tag !== tagToRemove);
  
        const dataToUpdate = {
          content: updatedContent,
          tags: updatedTags
        };
  
        await updateDoc(noteRef, dataToUpdate);
  
        setNotes(prevNotes => {
          const newNotes = prevNotes.map(note =>
            note.id === noteId
              ? { 
                  ...note, 
                  content: updatedContent,
                  tags: updatedTags 
                }
              : note
          );
          return newNotes;
        });
  
        setFilteredNotes(prevFilteredNotes => {
          const newFilteredNotes = prevFilteredNotes.map(note =>
            note.id === noteId
              ? { 
                  ...note, 
                  content: updatedContent,
                  tags: updatedTags 
                }
              : note
          );
          return newFilteredNotes;
        });
      }
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  const handleResetTagFilter = () => {
    onResetTag();
    setIsTagFilterActive(false);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="w-1/3"></div>
        <div className="w-1/3 flex justify-center">
          <div className="relative">
            <input
              type="text"
              placeholder="학생 이름 또는 키워드 검색"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-full w-64"
            />
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
          </div>
        </div>
        <div className="w-1/3 flex justify-end space-x-1">
          <button onClick={handleReportClick} className="p-2 rounded-full">
            <FaFileAlt />
          </button>
          <button onClick={toggleSortOrder} className="p-1 rounded-full">
            {sortOrder === 'desc' ? <FaSortAmountDown /> : <FaSortAmountUp />}
          </button>
        </div>
      </div>

      {isTagFilterActive && filteredNotes.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-64">
          <p className="text-gray-500 mb-4">태그로 필터링 된 카드가 없습니다.</p>
          <button
            onClick={handleResetTagFilter}
            className="px-4 py-2 bg-yellow-500 text-white rounded-3xl hover:bg-yellow-600"
          >
            태그 필터링 초기화
          </button>
        </div>
      ) : filteredNotes.length > 0 ? (
        <FlipMove className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 justify-center">
          {filteredNotes.map((note) => (
            <div
              key={note.id}
              className={`p-4 shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg text-[#353535] cursor-pointer ${note.color}`}
              onClick={() => handleNoteClick(note)}
              style={{ height: '220px' }}
            >
              <div className="flex flex-col h-full justify-between relative">
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-bold text-[#353535]">{note.student}</span>
                    <span className="px-2 py-1 rounded bg-white text-[#353535] text-xs">
                      {note.category}
                    </span>
                  </div>
                  <h3 className="text-lg font-semibold text-[#353535]">{note.title}</h3>
                  <div className="text-xs text-[#353535] mt-1" style={{ wordWrap: 'break-word' }}>
                    {getPlainText(note.content).length > 50
                      ? getPlainText(note.content).substring(0, 50) + '...'
                      : getPlainText(note.content)}
                  </div>
                </div>
                <div className="absolute bottom-0.5 right-0.5 flex items-center">
                  <span 
                    role="img" 
                    aria-label="delete" 
                    className="text-red-500 cursor-pointer hover:text-red-700 text-xs"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(note);
                    }}
                  >
                    🗑️
                  </span>
                </div>
                <div>
                  <div className="flex flex-wrap mt-2">
                    {note.tags &&
                      note.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="bg-[#353535] text-white rounded-full px-3 py-1 text-xs mr-2 mb-2 flex items-center"
                        >
                          {tag}
                          <FaTimes
                            className="ml-1 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveTag(note.id, tag);
                            }}
                          />
                        </span>
                      ))}
                  </div>
                  <div className="flex flex-col text-xs mt-2 text-[#353535]">
                    <span>{formatDate(note.date)}</span>
                    {note.updatedAt && (
                      <span className="text-gray-500">
                        수정됨 ({formatDate(note.updatedAt)})
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </FlipMove>
      ) : (
        <div className="flex justify-center items-center h-64">
          <div className="text-center text-gray-500">
            <p className="text-base font-light">🗃️ 폴더가 비어있어요. </p>
          </div>
        </div>
      )}
      
      {selectedNote && (
        <EditNoteModal
          isOpen={true}
          onClose={closeModal}
          note={selectedNote}
          onEdit={handleEditNote}
          user={user}
        />
      )}

      {isDeleteModalOpen && (
        <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} title="삭제 확인" width="w-[340px]">
          <div className="p-4">
            <p>이 상담 카드를 삭제하시겠습니까?</p>
            <div className="flex justify-center space-x-4 mt-8">
              <button 
                onClick={() => setIsDeleteModalOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                취소
              </button>
              <button 
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                삭제
              </button>
            </div>
          </div>
        </Modal>
      )}

      {isReportModalOpen && (
        <Modal isOpen={isReportModalOpen} onClose={() => setIsReportModalOpen(false)} title="상담 기록 리포트" width="w-[800px]">
          <div className="p-4">
            <div className="flex justify-end mb-4 space-x-4">
              <button
                onClick={toggleModalSortOrder}
                className="text-gray-500 hover:text-gray-700 transition-colors"
                title={modalSortOrder === 'desc' ? "가장 최근 순으로 정렬" : "가장 오래된 순으로 정렬"}
              >
                {modalSortOrder === 'desc' ? <FaSortAmountDown size={16} /> : <FaSortAmountUp size={16} />}
              </button>
              <button
                onClick={downloadExcel}
                className="text-gray-500 hover:text-gray-700 transition-colors"
                title="엑셀 다운로드"
              >
                <FaDownload size={16} />
              </button>
            </div>
            {sortedFilteredNotes.length > 0 ? (
              <div className="space-y-4 max-h-[70vh] overflow-y-auto">
                {sortedFilteredNotes.map((note) => (
                  <div key={note.id} className={`p-4 rounded-lg shadow ${note.color}`}>
                    <div className="flex justify-between items-start mb-2">
                      <h3 className="text-lg font-semibold">{note.title}</h3>
                      <span className="px-2 py-1 rounded bg-white text-[#353535] text-xs">
                        {note.category}
                      </span>
                    </div>
                    <p className="text-sm mb-2"><strong>학생:</strong> {note.student}</p>
                    <p className="text-sm mb-2"><strong>날짜:</strong> {formatDate(note.date)}</p>
                    {note.updatedAt && (
                      <p className="text-sm mb-2"><strong>수정일:</strong> {formatDate(note.updatedAt)}</p>
                    )}
                    <p className="text-sm mb-2"><strong>내용:</strong> {getPlainText(note.content)}</p>
                    <div className="flex flex-wrap mt-2">
                      {note.tags &&
                        note.tags.map((tag, index) => (
                          <span
                            key={index}
                            className="bg-[#353535] text-white rounded-full px-3 py-1 text-xs mr-2 mb-2"
                          >
                            {tag}
                          </span>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-64">
                <p className="text-gray-500">😐 카드가 존재하지 않습니다.</p>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CounselingNotes;